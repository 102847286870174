@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.add-user {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @include square(100%);
  z-index: 9;
  &__container {
    @include centre;
    background-color: $modal-bg;
    padding: 1rem;
    animation: fade 0.5s ease-out 1.3s both;
    * {
      transition: all 0.3s ease;
      color: $white;
    }
    .wrapper {
      min-width: 30rem;
      line-height: 2rem;
      header {
        @include flex(row, space-between, center);
        margin-bottom: 1rem;
        h3 {
          font: {
            weight: 300;
          }
        }
        img {
          @include square(0.8rem);
        }
      }
      .select-user {
        position: relative;
          .material-input {
            position: relative;
            width: 100%;
            @include flex(row, space-between, center);
          margin-bottom: 1rem;
          cursor: pointer;
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
            input,
            textarea {
                background-color: $grey-600;
            }
            input[type='text'],
            textarea {
                color: $white;
                font-size: 0.85rem;
                padding: 0.9rem 0.9rem 0.9rem 0.7rem;
                position: relative;
                display: block;
                border: none;
                width: 100%;
                border-bottom: 0.06rem solid $white;
                box-sizing: border-box;
                &:focus {
                    outline: none;
                }
            }
            input[type='text']:focus + label,
            input[type='text']:valid + label,
            textarea:focus + label,
            textarea:valid + label {
                top: -0.5rem;
                font-size: 0.6rem;
            }
            label {
                color: $white;
                font-size: 0.75rem;
                font-weight: 300;
                position: absolute;
                pointer-events: none;
                top: 6px;
                transition: 0.2s ease all;
                width: 100%;
                left: 0.75rem;
            }
        }
        .options {
          position: absolute;
          background-color: $dropbg;
          padding: 0.5rem;
          width: 100%;
          z-index: 99;
          top: 2.85rem;
          animation: options 0.3s ease;
          span {
            @include flex(row, flex-start, center);
            font-size: 0.75rem;
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
            img {
              @include square(1.75rem);
              border-radius: 50%;
              margin-right: 0.5rem;
            }
          }
        }
      }
      .role {
        &__options {
          label {
            display: flex;
            align-items: center;
            position: relative;
            font-size: 0.75rem;
          }

          input {
            display: none;
          }

          label > span {
            @include square(1.3rem);
            display: flex;
            justify-content: center;
            border: 0.06rem solid $white;
            margin-right: 0.5rem;
            transition: all 0.3s;
          }

          input:checked + label > span {
           
            animation: bounce 250ms;
          }

          input:checked + label > span::before {
            content: "";
            position: absolute;
            top: 0.5rem;
            left: 0.2rem;
            border-right: 0.1rem solid transparent;
            border-bottom: 0.1rem solid transparent;
            transform: rotate(45deg);
            transform-origin: 0% 100%;
            animation: checked-box 125ms 250ms forwards;
          }

          input:checked + label > span {
            background: $grey-500;
          }

          @keyframes checked-box {
            0% {
              width: 0;
              height: 0;
              border-color: #fff;
              transform: translate(0, 0) rotate(45deg);
            }
            33% {
              width: 4px;
              height: 8px;
              border-color: #fff;
              transform: translate(0, 0) rotate(45deg);
            }
            100% {
              width: 6px;
              height: 10px;
              border-color: #fff;
              transform: translate(0, -8px) rotate(45deg);
            }
          }

          @keyframes bounce {
            0% {
              transform: scale(1);
            }
            33% {
              transform: scale(0.7);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
      footer {
        @include flex(row, flex-start, center);
        border-top: 0.06rem solid $grey-450;
        gap: 0.75rem;
        margin: 1rem -1rem 0;
        padding: 1rem 1rem 0;
        button:disabled{
          background-color: #282836;
          color: #747480;
          border-color: #282836;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }
  &:before {
    content: "";
    box-sizing: border-box;
    width: 100%;
    background-color: $popup-bg;
    position: fixed;
    left: 0;
    top: 50%;
    will-change: height, top;
    animation: open-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) 0.65s both;
  }
  &:after {
    content: "";
    @include size(0, 0.1rem);
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 0;
    will-change: width, opacity;
    animation: line-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
  }
}
@keyframes options {
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
