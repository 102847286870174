@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.chat {
  @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
  max-height: calc(100vh - 5rem);
  overflow-y: hidden;
  position: relative;
  height: 100%;
  &__container {
    section {
      header {
        @include flex(row, flex-start, center);
        gap: 1rem;
        margin-bottom: 0.75rem;
        .name {
          @include flex(column, center, flex-start);
          line-height: 1rem;
          strong {
            color: $white;
            font: {
              size: 0.7rem;
            }
          }
          span {
            color: $light-white;
            font: {
              size: 0.6rem;
            }
          }
        }
        img {
          @include square(2.5rem);
          margin: 0 !important;
        }
      }
      p {
        border-radius: 1.5rem;
        background-color: $sidebar-bg;
        color: $white;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 1rem 1.5rem;
        display: inline-flex;
        border-top-left-radius: 0.5rem;
        max-width: 30rem;
      }
      footer {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        margin-top: 1rem;
        button {
          border-radius: 1rem;
          padding: 0.75rem 1.5rem;
          font-size: 0.6rem;
          text-transform: uppercase;
          display: flex;
          cursor: pointer;
          border: 0.06rem solid transparent;
          line-height: 0;
          &:first-child {
            background-color: $blue-450;
            color: $white;
          }
          &:last-child {
            background-color: transparent;
            border-color: $light-white;
            color: $light-white;
          }
        }
      }
      &.bot {
      }
      &.user {
      }
      &.user-yes-no {
        margin:-1.5rem 0 0 2rem;
      }
      &.agent {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        p {
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 0.5rem;
          background-color: $nav-bg;
          color: $app-bg;
        }
        .name {
          align-items: flex-end;
        }
        & > span {
          margin: 1rem 0;
          cursor: pointer;
          color: $white;
          font-size: 0.8rem;
          padding: 0.6rem 1rem;
          &.annotate {
            background-color: $sidebar-bg;
            border-radius: 0.75rem;
            &.hide {
              display: none;
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &.new-agent {
        @include flex(row, center, center);
        margin: 2.5rem 0 1rem;
        .detail {
          @include flex(column, center, center);
          border: 0.06rem solid $sidebar-border;
          border-radius: 1rem;
          color: $white;
          padding: 1.5rem 1.5rem 1rem;
          position: relative;
          min-width: 15rem;
          img {
            @include square(2.2rem);
            position: absolute;
            top: -1.2rem;
          }
          span {
            &:last-child {
              font-size: 0.6rem;
              color: $light-white;
            }
          }
          & > span {
            line-height: 1rem;
            font: {
              size: 0.75rem;
            }
          }
        }
      }
    }
  }
}

.ms-Persona-initials,
.initials-200,
.css-144 {
  display: none !important;
}

.ms-Stack {
  background-color: transparent !important;
}
.body-138 {
  background-color: transparent !important;
}
.bi {
  background-color: transparent !important;
}
.css-150,.css-151 {
  max-width: 100% !important;
}
.css-152{
  min-height: 1.2rem;
}
.css-90 {
  height: calc(100vh - 5.4rem);
}
.css-156{
  margin: 0;
}
.bv{
  width: 100% !important;
}
.css-157:focus-within,.css-157:hover,.css-157{
  border: none !important;
  margin: 0 !important;
}
.ms-TextField{
  &--multiline{
    padding: 1rem;
    background-color: $sidebar-bg;
    border-top: 0.06rem solid $sidebar-border;
    .ms-TextField-fieldGroup{
      background-color: transparent;
      textarea{
        background-color: $app-bg;
        border-radius: .75rem;
        color: $light-white;
        padding: .3rem 1rem 0 1rem;
      }
    }
  }
}

.ms-TooltipHost{
  button{
    outline: none;
    background-color: transparent;
    margin-right: 1.5rem;
    &:active{
      background-color: transparent;
    }
  }
}
// ACS customization
.ui-chat {
  margin: 1rem 1rem 0 1rem !important;
  *{
    font: {
      family: $ey-font-light;
  }
  }
  &__item {
    padding: 0 !important;
    &:empty,&__gutter {
      display: none !important;
    }
  }
}
[data-ui-id="typing-indicator"]{
  span{
    color: $grey-200;
    padding: 0 0 .1rem .65rem;
    font-size: .65rem;
    display: inline-block;
    &:last-child{
      padding-left: .25rem;
    }
  }
}