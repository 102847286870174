@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 1rem;
    min-width: 30rem;
    max-width: 30rem;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.20287);
    transition: all linear 0.3s;
    animation:slideInLeft .5s ease;
    right:1rem;
    top: 1rem;
    position:absolute;
    p{
        margin: 0;
        font-size: .8rem;
    }
    &--error{
        background-color: $grey-550;
        color: $white;
    }
    &--success{
        background-color: $grey-550;
        color: $white;
    }
    &__body{
      @include flex(row, flex-start, center);
      gap: .5rem;
      img{
        @include square(1.1rem);
      }
    }
  }
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(calc(100% + 32px));
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }