@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.annotate-details {
  background-color: $sidebar-bg;
  border-radius: 0.5rem;
  //margin: 1rem 0;
  &__container {
    padding: 1rem;
    .row {
      @include grid(30% 1fr, center, 0);
      span {
        color: $light-white;
        line-height: 1rem;
        font: {
          size: 0.8rem;
        }
      }
      textarea,
      input,
      button {
        border-radius: 0.35rem;
        background-color: lighten($sidebar-bg, 5%);
        border: none;
        outline: none;
        padding: 0.8rem;
        color: $white;
        width: 100%;
        font: {
          family: var(--primary-font);
          size: 0.8rem;
        }
      }
      textarea {
        height: 5rem;
        @include scrollbars(0.25rem, lighten($grey-450, 25%), $grey-450);
        resize: none;
      }
      .dropdown {
        position: relative;
        button {
          text-align: left;
          @include flex(row,flex-start,center);
          gap : 1rem;
          grid-gap: 1rem;
          height : 2.5rem;
          // img {
          //   position: absolute;
          //   right: 0.75rem;
          //   top: 1rem;
          //   @include square(0.5rem);
          //   cursor: pointer;
          //   transition: transform 0.3s ease;
          //   &.active {
          //     transform: rotate(180deg);
          //   }
          // }
          img.arrow{
            margin-left:auto;
            &.active {
              transform: rotate(180deg);
            }
          }
          img.pdf{
            width:1.1rem;
            height:1.1rem;
          }
        }
        ul {
          background-color: lighten($sidebar-bg, 5%);
          position: absolute;
          width: 100%;
          z-index: 10;
          animation: dropdown 0.4s ease;
          top: 2.4rem;
          li {
            padding: 0.75rem;
            list-style: none;
            @include flex(row, flex-start, center);
            color: $white;
            transition: all .3s ease;
            cursor: pointer;
            font: {
              size: 0.8rem;
            }
            img {
              @include square(1.1rem);
              margin-right: 0.5rem;
            }
            &:not(:last-child) {
              border-bottom: 0.06rem solid lighten($sidebar-bg, 11%);
            }
            &:hover {
              background-color: lighten($sidebar-bg, 3%);
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    footer{
        @include flex(row, flex-end, center);
        gap: .75rem;
        img{
            cursor: pointer;
            &:first-child{
                opacity: .5;
                @include square(.8rem);
            }
            &:last-child{
                @include square(.9rem);
            }
        }
    }
  }
}
@keyframes dropdown {
  from {
    transform: translateY(-1.5rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
