@import './assets/scss/base.scss';
::ng-deep{
    .ag-theme-alpine .ag-theme-alpine-dark{
        .ag-root-wrapper {
            border:none!important;
        }
    }
    .ag-header {
        border: none !important;
        background-color: #2B2B2F !important;
       margin-bottom: 0.1rem !important; 
    }
    .ag-row{
        background-color: #2B2B2F !important;
    }
    .ag-row-odd {
        background-color: #2B2B2F !important;
    }
    .ag-sort-mixed-icon{
      display : block !important;
    }

}