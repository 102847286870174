@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.loader-wrapper {
  background-color: $app-bg;
  @include square(100%);
  position: absolute;
  z-index: 999;
  &__container {
    @include centre;
    span {
      color: $white;
      position: relative;
      top: 1.5rem;
    }
    .loader {
      @include square(3rem);
      margin: auto;
      position: relative;
      &:before {
        content: "";
        @include size(3rem, 0.4rem);
        background: lighten($sidebar-bg, 8%);
        position: absolute;
        top: 60px;
        left: 0;
        border-radius: 50%;
        animation: shadow 0.5s linear infinite;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $yellow-400;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
        animation: jump 0.5s linear infinite;
      }
    }
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
