@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.blank-session {
  height: 100%;
  &__container {
    position: relative;
    @include square(100%);
    .message {
      @include centre;
      @include flex(column, center, center);
      color: $white;
      img {
        margin-bottom: 2rem;
        @include square(85%);
      }
      p {
        font: {
          size: 0.75rem;
        }
        color: $white;
        opacity: 0.5;
        margin-top: 0.5rem;
      }
    }
  }
}
