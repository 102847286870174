@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.displayText{
    transition: border-color 1s;
    border : 0.1rem solid $sidebar-bg;
    span.highlight{
        display:inline-block;
        background: #a5a5a6;
        padding: 0.2rem;
        color: $black;
        border-radius: 0.5rem;
    }
    &.highlighted{
        border : 0.1rem solid $yellow-400;
    }
}