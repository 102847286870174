@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.logout-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  @include square(100%);
  background-color: $app-bg;
  z-index: 99;
  animation: logout 1s ease;
  &__container {
    @include centre;
    background-color: darken($grey-600,10%);
    padding: 2rem;
    box-shadow: 0 2.5em .9em -2em $black;
    .card {
      @include flex(column, center, center);
      header {
        margin-bottom: 1.5rem;
        span {
          color: $white;
        }
      }
      p {
        font-size: 1rem;
        color: $white;
        margin-bottom: 2rem;
      }
      button {
        background: $yellow-400;
        color: $grey-500;
        font: {
          size: 0.8rem;
          weight: 600;
        }
        padding: .75rem;
        min-width: 10rem;
        margin: 0 0 7px;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 0.6rem;
        box-shadow: 0 5px darken($yellow-400, 5%), 0 5px 5px 1px #4f4f4f6e;
        transition: box-shadow 0.1s, margin 0.1s;
        &:active {
          margin: 7px 0 0;
          box-shadow: 0 0 darken($yellow-400, 5%), 0 0 #2f2f2f,
            0 3px 14px darken($yellow-400, 2%) inset;
        }
      }
    }
  }
}
@keyframes logout {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes up-down {
  from {
    transform: translatey(0px);
  }
  to {
    transform: translatey(-20px);
  }
}
