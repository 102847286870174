@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.agent-wrapper {
  &__container {
    background-color: $app-bg;
    height: 100vh;
    @include grid(18% 1fr, flex-start, 0);
    .contents {
      height: 100%;
    }
  }
}
@keyframes calling {
  from {
    background: $green-400;
    opacity: 0.1;
  }
  to {
    background: $green-400;
    opacity: 0.9;
  }
}
@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes jump {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}
