@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.chat-session {
    height: 100%;
  &__container {
    @include grid(35% 1fr, flex-start, 0);
    height: 100%;
  }
}
