@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.ag-overlay {
  .loader-wrapper {
    background-color: #2b2b2f;
    top: 0;
    left: 0;
    opacity: 0.7;
    // &__container {
    //     span {
    //       color: $black;
    //     }
    // }
  }
}
.ag-theme-alpine {
  width: 100%;
  height: calc(100vh - 13rem);
  font: {
    family: $ey-font-light !important;
  }
  .ag-icon-asc {
    background: transparent url('../../../../assets/media/images/sort_up.svg') center/contain no-repeat;
    color: transparent;
    @include square(0.7rem);
  }
  .ag-icon-desc{
    background: transparent url('../../../../assets/media/images/sort_down.svg') center/contain no-repeat;
    color: transparent;
    @include square(0.7rem);
  }
  .ag-icon-none{
    background: url('../../../../assets/media/images/sort_up.svg') left top no-repeat,url('../../../../assets/media/images/sort_down.svg') left bottom no-repeat;
    color: transparent;
    @include square(0.8rem);
  }
  .ag-body-viewport.ag-layout-normal {
    @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
  }
  .ag-theme-alpine .ag-header {
    border: none !important;
    background-color: #2b2b2f !important;
    margin-bottom: 0.1rem !important;
  }
  .ag-root-wrapper {
    border: none !important;
    background-color: #2b2b2f !important;
  }
  .ag-header {
    border: none !important;
    background-color: #2b2b2f !important;
    margin-bottom: 0.1rem !important;
    color: #d8d8dc;
  }
  .ag-header-row {
    color: #d8d8dc !important;
    border-bottom: 0.06rem solid $grey-450 !important;
    .ag-header-cell-text {
      font: {
        size: 0.7rem !important;
      }
    }
  }
  .ag-row {
    background-color: $grey-650 !important;
    color: $white;
    border-bottom: 0.06rem solid $grey-450 !important;
    .ag-cell {
      color: $white;
      font: {
        size: 0.75rem !important;
      }
      &:not(:first-child) {
        padding-top: 0.5rem;
      }
      .avatar-cell-renderer {
        img {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }
  }
  .ag-row-odd {
    background-color: #2b2b2f !important;
    color: $white;
  }
  .ag-sort-mixed-icon {
    display: block !important;
  }
  .ag-paging-panel {
    background: #222222 !important;
    border: none !important;
    color: white !important;
  }
}
.active-users,.user-request {
  .action-cell-renderer {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    img {
      @include square(0.8rem);
    }
  }
  .ag-overlay-loading-center{
    color: $white;
  }
}
.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: transparent !important;
}
.ag-paging-panel{
    font-size: .7rem;
    .ag-paging-row-summary-panel{
        margin-right: 0;
    }
}
.ag-header-row.ag-header-row-column:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #232323;
}
