@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.chat-history-users {
  height: 100%;
  background-color: $sidebar-bg;
  &__header{
    border-bottom: 0.06rem solid $sidebar-border;
    border-right: 0.06rem solid $sidebar-border;
    background-color: $sidebar-bg;
    h2 {
      color: $nav-bg;
      padding: 1.8rem;
    }
  }
  &__container {
    padding: 0.75rem;
    border-right: 0.06rem solid $sidebar-border;
    height: 100%;
    .search {
      background-color: $app-bg;
      padding: .75rem;
      @include flex(row, space-between, center);
      gap: .5rem;
      margin-bottom: .75rem;
      img{
        @include square(1rem);
        cursor: pointer;
        opacity: .5;
      }
      input{
        width: 100%;
        color:$white;
        background-color: transparent;
        border: none;
        outline: none;
        &::placeholder{
          font-size: .75rem;
          color:$light-white;
        }
      }
    }
    .users-wrapper{
      @include scrollbars(0.4rem, lighten($grey-450, 25%), $grey-450);
      max-height: calc(100vh - 10rem);
      overflow-y: auto;
      .users-lists {
        @include flex(row, space-between, center);
        background-color: $grey-350;
        padding: 0.75rem;
        border-radius: 0.5rem;
        .left {
          @include flex(row, flex-start, center);
  
          img {
            @include square(2.3rem);
            margin-right: 0.75rem;
          }
          &__action {
            @include flex(column, center, flex-start);
            span {
              color: $light-white;
              cursor: pointer;
              font: {
                size: 0.7rem;
              }
            }
            strong {
              color: $white;
              margin-bottom: 0.2rem;
              font: {
                size: 0.75rem;
              }
            }
          }
        }
        .right {
          @include flex(column, center, flex-end);
          gap: 0.35rem;
          span {
            color: $light-white;
            font-size: 0.65rem;
          }
          img {
            @include square(0.8rem);
          }
        }
        &:not(:last-child) {
          margin-bottom: .75rem;
        }
        &.selected{
          background-color: $grey-400;
        }
      } 
      .card {
        background-color: $grey-350;
        padding: 1rem;
        border-radius: 0.5rem;
        .header {
          display: flex;
          align-items: center;
          .img {
            @include square(2.3rem);
            background: $grey-450;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
          }
          .details {
            margin-left: 20px;
            span {
              display: block;
              background: $grey-450;
              border-radius: 1rem;
              overflow: hidden;
              position: relative;
              &.name {
                @include size(15rem, 0.55rem);
              }
              &.about {
                @include size(10rem, 0.4rem);
                margin-top: 10px;
              }
            }
          }
        }
      }
  
      .header .img::before,
      .details span::before,
      h1 span::before {
        position: absolute;
        content: "";
        @include square(100%);
        background-image: linear-gradient(
          to right,
          #656871 0%,
          #888b94 20%,
          #656871 40%,
          #656871 100%
        );
        background-repeat: no-repeat;
        background-size: 450px 400px;
        animation: shimmer 1.5s linear infinite;
      }
      .header .img::before {
        background-size: 650px 600px;
      }
      .details span::before {
        animation-delay: 0.3s;
      }

      }
      @keyframes shimmer {
        0% {
          background-position: -450px 0;
        }
        100% {
          background-position: 450px 0;
        }
      }
    }
  }
