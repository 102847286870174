@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.skelton-loader {
  background-color: $sidebar-bg;
  position: absolute;
  z-index: 99;
  @include size(28.7%, 100%);
  &__container {
    padding: 0.75rem;
    border-right: 0.06rem solid $sidebar-border;
    height: 100%;
    h1 {
      border-bottom: 0.06rem solid $sidebar-border;
      height: 4.64rem;
      margin: 0 -.75rem;
      padding: .75rem;
      @include flex(row, flex-start, center);
      span {
        @include size(13rem, 0.6rem);
        position: relative;
        display: block;
        border-radius: 1rem;
        overflow: hidden;
      }
    }
    .card {
      background-color: $grey-350;
      padding: 1rem;
      border-radius: 0.5rem;
      margin-top: 1rem;
      .header {
        display: flex;
        align-items: center;
        .img {
          @include square(2.3rem);
          background: $grey-450;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
        }
        .details {
          margin-left: 20px;
          span {
            display: block;
            background: $grey-450;
            border-radius: 1rem;
            overflow: hidden;
            position: relative;
            &.name {
              @include size(15rem, 0.55rem);
            }
            &.about {
              @include size(10rem, 0.4rem);
              margin-top: 10px;
            }
          }
        }
      }
    }

    .header .img::before,
    .details span::before,
    h1 span::before {
      position: absolute;
      content: "";
      @include square(100%);
      background-image: linear-gradient(
        to right,
        #656871 0%,
        #888b94 20%,
        #656871 40%,
        #656871 100%
      );
      background-repeat: no-repeat;
      background-size: 450px 400px;
      animation: shimmer 1.5s linear infinite;
    }
    .header .img::before {
      background-size: 650px 600px;
    }
    .details span::before {
      animation-delay: 0.3s;
    }
    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }
      100% {
        background-position: 450px 0;
      }
    }
  }
}
