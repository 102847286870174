@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.approve-reject {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @include square(100%);
  z-index: 9;
  &__container {
    @include centre;
    background-color: $modal-bg;
    padding: 1rem;
    animation: fade 0.5s ease-out 1.3s both;
    * {
      transition: all 0.3s ease;
      color: $white;
    }
    .wrapper {
      min-width: 30rem;
      line-height: 2rem;
      header {
        @include flex(row, space-between, center);
        margin-bottom: 1rem;
        h3 {
          font: {
            weight: 300;
          }
        }
        img {
          @include square(0.8rem);
          cursor: pointer;
        }
      }
      p{
        font-size: .9rem;
        margin-bottom: 2rem;
      }
      footer {
        @include flex(row, flex-start, center);
        border-top: 0.06rem solid $grey-450;
        gap: 0.75rem;
        margin: 1rem -1rem 0;
        padding: 1rem 1rem 0;
      }
    }
  }
  &:before {
    content: "";
    box-sizing: border-box;
    width: 100%;
    background-color: $popup-bg;
    position: fixed;
    left: 0;
    top: 50%;
    will-change: height, top;
    animation: open-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) 0.65s both;
  }
  &:after {
    content: "";
    @include size(0, 0.1rem);
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 0;
    will-change: width, opacity;
    animation: line-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
  }
}
