@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.agent-header {
  &__container {
    @include grid(34.95% 1fr, center, 0);
    border-bottom: 0.06rem solid $sidebar-border;
    background-color: $sidebar-bg;
    h2 {
      color: $nav-bg;
      padding: 1.75rem;
    }
    .details {
      border-left: 0.06rem solid $sidebar-border;
      padding: 1.4rem;
      @include flex(row, space-between, center);
      &__left {
        @include flex(row, flex-start, center);
        img {
          @include square(2.5rem);
          margin-right: 0.75rem;
        }
        &__action {
          @include flex(column, center, flex-start);
          span {
            color: $light-white;
            cursor: pointer;
            font: {
              size: 0.7rem;
            }
          }
          strong {
            color: $white;
            margin-bottom: 0.3rem;
            font: {
              size: 1rem;
              weight: inherit;
            }
          }
        }
      }
      &__right {
        .leave {
            background-color: rgba(217, 217, 217, 0.15);
            @include flex(row, flex-start, center);
            padding: .5rem 1rem;
            border-radius: .8rem;
            cursor: pointer;
            img{
                width: 1rem;
                margin-right: .5rem;
            }
            span{
                color: $white;
                font-size: .8rem;
                position: relative;
                top:-.1rem
            }
        }
      }
    }
  }
}
