@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.status-cell-renderer{
  @include flex(row,flex-start,center);
  gap: 0.5rem;
  &__circle{
        @include square(.9rem);
        border-radius: 50%;
        display: inline-block;
  }
}