@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.filter-wrapper {
  position: absolute;
  background-color: darken($grey-600, 2%);
  width: 17rem;
  top: 2rem;
  z-index: 99;
  left: -13rem;
  animation: fade .3s ease;
  .top {
    padding: 1rem;
    h5 {
      color: $white;
      font: {
        size: 0.85rem;
        weight: 400;
      }
    }
  }
  .top > h5 {
    margin-bottom: 1rem;
  }
  footer{
      padding: .75rem 1rem;
      border-top: .06rem solid lighten($grey-450,5%);
      @include flex(row, flex-start, center);
      gap:.5rem;
  }
  &__options {
      margin-bottom: 1.5rem;
      &:last-child{
        margin-bottom: 1rem;
      }
      h5{
          margin-bottom: .75rem;
      }
      .list{
          margin-bottom: 1rem;
      }
    label {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 0.75rem;
      color: $white;
    }

    input {
      display: none;
    }

    label > span {
      @include square(1.3rem);
      display: flex;
      justify-content: center;
      border: 0.06rem solid $white;
      margin-right: 0.5rem;
      transition: all 0.3s;
    }

    input:checked + label > span {
      border: 0.06rem solid transparent;
      animation: bounce 250ms;
    }

    input:checked + label > span::before {
      content: "";
      position: absolute;
      top: 0.5rem;
      left: 4px;
      border-right: 0.1rem solid transparent;
      border-bottom: 0.1rem solid transparent;
      transform: rotate(45deg);
      transform-origin: 0% 100%;
      animation: checked-box 125ms 250ms forwards;
    }

    input:checked + label > span {
      background: $grey-500;
    }

    @keyframes checked-box {
      0% {
        width: 0;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
      }
      33% {
        width: 4px;
        height: 8px;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
      }
      100% {
        width: 6px;
        height: 10px;
        border-color: #fff;
        transform: translate(0, -8px) rotate(45deg);
      }
    }

    @keyframes bounce {
      0% {
        transform: scale(1);
      }
      33% {
        transform: scale(0.7);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
