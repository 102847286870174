$black: #000;
$app-bg: #1a1a1a;
$white: #fff;
$yellow-400: #ffe600;
$grey-650:#2B2B2F;
$grey-600: #23232f;
$grey-550: #2e2e3c;
$grey-500: #2e2e38;
$grey-450: #3a3a4a;
$grey-400:   #303030;
$grey-350: #1c1c1c;
$grey-300: #c4c4cd;
$grey-200: #eaeaf2;
$modal-bg:#23232F;
$dropbg:#282836;

$green-400: #2ecd70;
$green-450: #3ec475;
$green-500: #189d3e;
$green-600: #168736;
$orange-300: #ff810a;
$red-300:#F95D54;
$red-400: #e64e3d;
$red-500: #e0362c;
$blue-400: #188ce5;
$blue-450: #2f53f0;

$sidebar-bg: #222222;
$sidebar-border: #e9eaeb26;
$nav-bg: #fffd54;
$light-white: rgba(255, 255, 255, 0.5);
$popup-bg: #000000d9;

$colors: #42a5f5 #2196f3 #1e88e5 #1976d2 #1565c0;

// Images
$image-base: "../../assets/media/images/";
