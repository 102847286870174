@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.incomming-chat-session {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  animation: vibrate 1.5s linear infinite both;
  &__container {
    padding: 3rem 3.5rem;
    box-shadow: 6px -9px 38px #000000c7;
    background-color: $app-bg;
    border-radius: 2rem;
    header {
      @include flex(column, center, center);
      margin-bottom: 1.5rem;
      img {
        @include square(3.5rem);
        margin-bottom: 1rem;
      }
      strong {
        color: $white;
        font: {
          size: 0.9rem;
        }
      }
      .calling {
        @include flex(row, flex-start, center);
        color: $green-400;
        margin-top: 0.3rem;
        span {
          font-size: 0.7rem;
        }
        .status {
          margin-left: 0.2rem;
          @include flex(row, flex-start, center);
          gap: 0.2rem;
          span {
            @include square(0.175rem);
            border-radius: 100%;
            animation: calling 1s backwards infinite;
            position: relative;
            top: 0.1rem;
            @for $i from 1 through 3 {
              &:nth-child(#{$i}) {
                animation-delay: $i * 0.3 + s;
              }
            }
          }
        }
      }
    }
    .actions {
      @include flex(row, center, center);
      gap: 1.75rem;
      span {
        border-radius: 100%;
        box-shadow: -3px -3px 9px rgba(255, 255, 255, 0.09);
        background-color: $app-bg;
        @include square(2.5rem);
        @include flex(row, center, center);
        cursor: pointer;
        &:last-child {
          @include square(3.5rem);
        }
        &:hover{
            animation: vibrate .3s linear infinite both;
        }
      }
    }
  }
}
