@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.leave-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @include square(100%);
  z-index: 9;
  &__container {
    @include centre;
    background-color: $app-bg;
    border-radius: 0.75rem;
    padding: 2rem;
    animation: fade 0.5s ease-out 1.3s both;
    * {
      transition: all 0.3s ease;
    }
    .leave-wrapper {
      min-width: 30rem;
      line-height: 2rem;
      footer {
        @include flex(row, flex-end, center);
        gap: 0.5rem;
        margin-top: 1rem;
      }
    }
    .strength {
      @include flex(row, center, center);
      min-width: 25rem;
      padding: 0.5rem 0;
      &__container {
        @include flex(column, center, center);
        width: 15rem;
        color: $white;
        img {
          margin-bottom: 2rem;
        }
        p {
          text-align: center;
          margin: 0.75rem 0 2rem;
        }
        .yes {
          min-width: 7rem;
        }
      }
    }
    strong {
      color: $white;
      font: {
        weight: 400;
      }
    }
    p {
      color: $light-white;
      font: {
        size: 0.85rem;
      }
    }
    button {
      padding: 0.75rem 2rem;
      border-radius: 1rem;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 0.9rem;
      &.cancel {
        color: $light-white;
        background-color: $app-bg;
        padding: 0.75rem 1.5rem;
        cursor: pointer;
      }
      &.yes {
        background-color: $nav-bg;
        color: $black;
        cursor: pointer;
      }
    }
  }
  &:before {
    content: "";
    box-sizing: border-box;
    width: 100%;
    background-color: $popup-bg;
    position: fixed;
    left: 0;
    top: 50%;
    will-change: height, top;
    animation: open-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) 0.65s both;
  }
  &:after {
    content: "";
    @include size(0, 0.1rem);
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 0;
    will-change: width, opacity;
    animation: line-animation 0.6s cubic-bezier(0.83, 0.04, 0, 1.16) both;
  }

  .dots-circle-spinner {
    margin-left: 0.5rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    transform: scale(0);
    opacity: 0;
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em,
      0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em,
      -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em,
      0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
  .dots-circle-spinner.loading {
    transform: scale(0.25);
    opacity: 1;
    animation: 1.5s linear 150ms normal infinite forwards running
      dots-circle-rotation;
  }
}


@keyframes dots-circle-rotation {
  100% {
  box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
  }
  87.5% {
  box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
  }
  75% {
    box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
  }
  62.5% {
    box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
  }
  50% {
     box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em, -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em, 1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em, -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em, 1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    }
  }
