@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.action-cell-renderer{
  @include flex(row,flex-start,center);
  margin-top: 1rem;
  gap: 1rem;
  .enabledAction{
    cursor: pointer;
  }
  .disabledAction{
    cursor: not-allowed;
    pointer-events: none;
    img{
        filter:invert(50%);
    }

  }
}